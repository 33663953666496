import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SchoolService } from 'src/app/service/school.service';
import { School } from 'src/app/model/school';
import { ActivatedRoute } from '@angular/router';
import { ServerDetails } from 'src/app/service/server-details';
import { SpinnerService } from 'src/app/spinner-service.service';
import { Staff } from 'src/app/model/staff';
import { UpperCasePipe } from '@angular/common';
import { WebUrlService } from 'src/app/service/web-url.service';

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss']
})
export class SchoolComponent implements OnInit, OnDestroy {
  title:string =""
  school: any;
  dean:any;
  serverDetails = new ServerDetails();
  myUrl;

  constructor(
    private spinner:SpinnerService,
    private schoolService:SchoolService,
    private route:ActivatedRoute,
    public webUrlService:WebUrlService,
  ) {
      this.route.paramMap.subscribe(info => {
        this.myUrl = info.get('id');
        let id = webUrlService.getID(this.myUrl);
        this.schoolService.getSchoolById(id).subscribe((data:any)=>{

          this.school = data;
		console.log(data);

          this.title = this.school.name
          this.getDean(data);
          window.scroll(0,0);
        })
      });
      this.serverDetails;
    }

    ngOnInit(): void {
    this.spinner.stop();
  }
  ngOnDestroy():void {
    this.spinner.start();
  }

  getDean(data){
    var dean = null;
    try {
      this.dean = data.my_staff.filter(staff => {
        return staff.staff_details.position && (staff.staff_details.position.toString().trim().toUpperCase().includes('DEAN') || staff.staff_details.position.toString().trim().toUpperCase().includes('DIRECTOR'));
      })[0];

    } catch (error) {
      console.log(error);
    }

  }

  getBackgroundImage(url:string){

    return `url(${this.serverDetails.serverUrlForStorage}/${url}) no-repeat center center/cover`;// "url("+this.serverDetails.serverUrlForStrorage+'/'+news.picUrl+") no-repeat  center center/cover";
  }


}
