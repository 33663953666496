import { Component } from "@angular/core";
import { NewsService } from "src/app/service/news.service";
import { News } from "src/app/model/news";
import { Events } from "src/app/model/events";
import { EventsService } from "src/app/service/events.service";
import { ServerDetails } from "src/app/service/server-details";
import { DataMonitorService } from "../data-monitor.service";
import { NoticeService } from "src/app/service/notice.service";
import { Notice } from "src/app/model/notice";

@Component({
	selector: "app-news",
	// tslint:disable-next-line:indent
	templateUrl: "./news.component.html",
	styleUrls: ["./news.component.scss"],
})
export class NewsComponent {
	newsData: Array<any>;
	eventsData: Array<Events>;
	serverDetails = new ServerDetails();
	notices: Notice[];
	newsLetter: any;
	constructor(
		private newsService: NewsService,
		private noticeService: NoticeService,
		private eventsService: EventsService,
		private dataMonitor: DataMonitorService
	) {
		this.newsService.getAllNews().subscribe((data: Array<any>) => {
			this.newsData = data;
			// tslint:disable-next-line:indent
			this.dataMonitor.changeStatus();
		});

		this.eventsService
			.getFrontPageEvents()
			.subscribe((data: Array<Events>) => {
				this.eventsData = data;
				this.dataMonitor.changeStatus();
			});

		this.noticeService.getAllnewsletter().subscribe((notices) => {
			// Filter notices by category "newsletter"
			this.newsLetter = notices.filter(
				(notice) => notice.notice_category_id === 24
			);

			// Assign all notices to the notices variable
			this.notices = notices.filter(
				(notice) => notice.notice_category_id !== 24
			);

			// Determine how many placeholder elements to add
			const len = notices.length > 8 ? 0 : 7 - notices.length;

			// Slice the newsData array to include only the required number of placeholders
			this.newsData = this.newsData.slice(0, len);
		});
	}
}
