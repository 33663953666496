import { Injectable } from '@angular/core';
import { Program } from '../model/program';
import { ProgramData } from '../data/program-data';
import { DepartmentService } from './department.service';
import { NormalEntryRequiremtData } from '../data/normal-entry-requiremt-data';
import { MatureEntryRequiremtData } from '../data/mature-entry-requiremt-data';
import { SpecialEntryRequiremtData } from '../data/special-entry-requiremt-data';
import { Requirement } from '../model/requirement';
import { Opportunity } from '../model/opportunity';
import { CareerOpportunityData } from '../data/career-opportunity-data';
import { JobOpportunityData } from '../data/job-opportunity-data';
import { SchoolData } from '../data/school-data';
import { ServerDetails } from './server-details';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  serverDetails = new ServerDetails();

  constructor(private http: HttpClient) { }

  getProgramById(id:number): Observable<Program> {
    return this.http.get<Program>(this.serverDetails.serverUrlForApi+'/programs/'+id);
  }

  public getRequirement(id,index):Observable<any>{
    return this.http.get(this.serverDetails.serverUrlForApi+'/programs/getProgramEntryRequirements/'+id+'/'+index);
  }

  getAllPrograms(): Observable<Array<Program>>{
    return this.http.get<Array<Program>>(this.serverDetails.serverUrlForApi+'/programs');
  }

  getDepartmentalPrograms(departmentId:number):Observable<Array<Program>>{
    return this.http.get<Array<Program>>(this.serverDetails.serverUrlForApi+'/programs/departmentalPrograms/'+departmentId);
  }

  getMinifiedProgramJobsById(programId:number):string{
    return "";
  }


  programContact(data:any){
    return this.http.post(this.serverDetails.serverUrlForApi+ "/getProgramContact",data);
  }

  downloadFile(id: number): Observable<Blob> {
    const headers = new HttpHeaders({
      'Accept': 'application/pdf' // Adjust this according to the file type
    });
    return this.http.get(`${this.serverDetails.serverUrlForApi}/getCourseSynopsis/${id}`, { responseType: 'blob', headers: headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Error downloading file:', error);
          return throwError('Something went wrong; please try again later.');
        })
      );
  }
}
