import { Component, OnInit, Input } from '@angular/core';
import { Staff } from 'src/app/model/staff';
import { ServerDetails } from 'src/app/service/server-details';


@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
	@Input('staff') staff: Staff;
	@Input('storage') storage: boolean;
	serverDetails:ServerDetails = new ServerDetails();
	constructor() { }

	ngOnInit(): void {
	}

}
