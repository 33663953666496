import { Component, OnInit } from '@angular/core';
import { School } from 'src/app/model/school';
import { SchoolService } from 'src/app/service/school.service';
import { DirectorateService } from 'src/app/service/directorate.service';
import { Router } from '@angular/router';
import { Directorate } from 'src/app/model/directorate';
import { WebUrlService } from 'src/app/service/web-url.service';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent implements OnInit {

  schools: any[] = [];
  directorates: Directorate[];

  constructor(
    private schoolService: SchoolService,
    private directorateService: DirectorateService,
    private router: Router,
    public webUrlService:WebUrlService,
  ) {
    // schoolService.getAllSchools().subscribe((data:Array<School>)=>{
    //   this.schools = data;
    // });
    schoolService.getAllSchools().subscribe((data:Array<any>)=>{
      this.schools = data.map(info=>{info.url=this.getUrl(info.id); return info;});
      //   lodza.unsubscribe();
      // console.log(this.schools)
    });
    directorateService.getAll().subscribe(data=>{
      this.directorates = data;
    })
  }

  ngOnInit(): void {


      document.querySelector('.first-button').addEventListener('click', function () {

        document.querySelector('.animated-icon1').classList.toggle('open');
      });

  }

  getUrl(id){
    return this.webUrlService.getUrl(id);
  }
  directoryUrl(directorate){
	if(directorate.url){
		return directorate.url;
	}
	return 'directorate/'+directorate.id;
}

}
