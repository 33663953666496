import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from 'src/app/spinner-service.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {
  title:string = 'About CUT'
  constructor(
    private spinner:SpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.stop();
  }
  ngOnDestroy():void {
    this.spinner.start();
  }

}
