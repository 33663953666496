import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerDetails } from './server-details';

@Injectable({
  providedIn: 'root'
})
export class ProgrammeAttendanceTypeService {
  serverDetails = new ServerDetails();

  constructor(private http: HttpClient) { }

  addProgramAttendanceType(data:any){
    return this.http.post(this.serverDetails.serverUrlForApi + "/addProgrammeAttendanceType",data);
  }
}
