import { SearchService } from '../../service/search.service';
import { Router } from '@angular/router';
import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-search-cut",
	templateUrl: "./search-cut.component.html",
	styleUrls: ["./search-cut.component.scss"],
})
export class SearchCutComponent implements OnInit {
	constructor(
		private searchService:SearchService,
		private router:Router
	) {}

	ngOnInit(): void {}

	search(query){

		this.searchService.search(query).subscribe(info=>{
			console.log(info);
			if(query.length<3){
				alert('Search must be at least three(3) character')
			}
			document.getElementById('closeSearchModal').click();
			this.router.navigate(['search-result', { query: query}]);

		})
	}


}
