// footer.component.ts

import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  private intervalId: any; // Variable to hold the interval ID
  footerText: string = '&copy; 2020 Chinhoyi University of Technology'; // Initial footer text

  constructor() { }

  ngOnInit(): void {
    this.updateFooterYear(); // Initial call to update footer year
    this.intervalId = setInterval(() => {
      this.updateFooterYear(); // Update footer year periodically
    }, 1000 * 60 * 60 * 24); // Update every 24 hours
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId); // Clear the interval when component is destroyed
  }

  // Define the function to update footer year
  updateFooterYear(): void {
    const currentYear: number = new Date().getFullYear();
    this.footerText = `&copy; ${currentYear} Chinhoyi University of Technology`;
  }
}
