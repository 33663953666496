import { Component, AfterViewInit, ViewChild } from "@angular/core";
import * as $ from "jquery";
import "ekko-lightbox";
import { GalleryService } from "src/app/service/gallery.service";
import { Gallery, GalleryResponse } from "src/app/model/gallery";
import { GalleryCategory } from '../../../model/gallery-category';

@Component({
    selector: "app-gallery",
    templateUrl: "./gallery.component.html",
    styleUrls: ["./gallery.component.scss"],
})
export class GalleryComponent implements AfterViewInit {
    totalPages = 5;
    currentPage = 1;
    @ViewChild("photoContainer") photoContainer: HTMLElement;

    pictures: Gallery[] = [];
    galleryCategories: any;
    selectedCategory: any;

    imagePath: any = 'https://www.cut.ac.zw/cut-back/public/storage/gallery_photos/';
    modalImageSrc: string = '';

    constructor(private galleryService: GalleryService) {}

    ngAfterViewInit(): void {
        $(document).on("click", '[data-toggle="lightbox"]', function (event) {
            event.preventDefault();
            const $lightbox = $(this);
            $lightbox.data("ekkoLightbox").show($lightbox);
        });
    }

    ngOnInit() {
        this.getGalleryCategory();
    }

    changePage(page: number): void {
        this.currentPage = page;
    }

    slide(left: boolean = false) {
        if (left) document.getElementById("photo-container").scrollLeft += 220;
        else document.getElementById("photo-container").scrollLeft -= 220;
    }

    getGalleryCategory() {
        this.galleryService.galleryCategories().subscribe(res => {
            this.galleryCategories = res;
            console.log(this.galleryCategories);
            if (this.galleryCategories.length > 0) {
                this.selectCategory(this.galleryCategories[0].gallery_category_id);
            }
        });
    }

    selectCategory(galleryCategoryId: number) {
        this.selectedCategory = galleryCategoryId;
        this.galleryService.galleryPictures(galleryCategoryId).subscribe(images => {
            this.pictures = images;
            console.log(this.pictures);
        });
    }

    setModalImage(src: string) {
        this.modalImageSrc = src;
    }
}
