import { Component, OnInit } from "@angular/core";
import { EventsService } from "src/app/service/events.service";

@Component({
	selector: "app-fees-structure",
	templateUrl: "./fees-structure.component.html",
	styleUrls: ["./fees-structure.component.scss"],
})
export class FeesStructureComponent implements OnInit {
	title = "Fees Structure";
	feesStructure: Array<{ dateCreated; description; link }> = [
		{
			dateCreated: "03/02/2025",
			description: "Institute of Material Sciences Undergrad  ",
			link: "assets/files/fees-structure/Institute of Material Sciences Fees Structure - Undergrad.pdf",
		},
		{
			dateCreated: "03/02/2025",
			description:
				"School of Business Sciences Fees Structure- Undergrad",
			link: "assets/files/fees-structure/School of Business Sciences Fees Structure - Undergrad.pdf",
		},
		{
			dateCreated: "03/02/2025",
			description:
				"School of Engineering Science Fees Structure - Undergrad",
			link: "assets/files/fees-structure/School of Engineering Sciences Fees Structure - Undergrad.pdf",
		},
		{
			dateCreated: "03/02/2025",
			description:
				"School of Agricultural Sciences Fees Structure - Undergrad",
			link: "assets/files/fees-structure/School of Agriculture Fees Structure - Undergrad.pdf",
		},
		{
			dateCreated: "03/02/2025",
			description: "School of Art & Design Fees Structure- Undergrad",
			link: "assets/files/fees-structure/School of Art & Design Fees Structure - Undergrad.pdf",
		},
		{
			dateCreated: "03/02/2025",
			description:
				"School of Natural Sciences Fees Structure - Undergrad",
			link: "assets/files/fees-structure/School of Natural Sciences Fees Structure - Undergrad.pdf",
		},
		{
			dateCreated: "03/02/2025",
			description:
				"School of Wildlife & Ecology Fees Structure - Undergrad",
			link: "assets/files/fees-structure/School of Wildlife Fees Structure - Undergrad.pdf",
		},
		{
			dateCreated: "03/02/2025",
			description:
				"School of Hopsitality & Tourism Fees Structure - Undergrad",
			link: "assets/files/fees-structure/School of Hospitality & Tourism Fees Structure - Undergrad.pdf",
		},
		{
			dateCreated: "03/02/2025",
			description: "School of Health Sciences - Undergrad",
			link: "assets/files/fees-structure/School of Health Sciences Fees Structure - Undergrad.pdf",
		},
	];
	$events;
	constructor(private eventsService: EventsService) {
		this.eventsService.getAllEvents().subscribe((data) => {
			this.$events = data;
		});
	}

	ngOnInit(): void {}
}
