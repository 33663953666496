import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { ApplyButtonComponent } from './apply-button/apply-button.component';
import { SearchCutComponent } from './search-cut/search-cut.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderComponent } from './header/header.component';
import { CutBrandComponent } from './cut-brand/cut-brand.component';
import { SeperatorComponent } from './seperator/seperator.component';
import { NgxArcTextModule } from 'ngx-arc-text';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { AlertsComponent } from './alerts/alerts.component';
import { StaffComponent } from './staff/staff.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ProfileComponent } from './profile/profile.component';
import { AppyNowLgComponent } from './appy-now-lg/appy-now-lg.component';
import { NoticeComponent } from './notice/notice.component';
import { HeaderTwoComponent } from './header-two/header-two.component';

@NgModule({
  declarations: [ApplyButtonComponent, AppyNowLgComponent, SearchCutComponent, FooterComponent, NavbarComponent, HeaderComponent, CutBrandComponent, SeperatorComponent, MobileNavComponent, AlertsComponent,StaffComponent, SpinnerComponent, ProfileComponent, AppyNowLgComponent, NoticeComponent, HeaderTwoComponent],
  exports: [ AppyNowLgComponent, ApplyButtonComponent, SearchCutComponent, FooterComponent, NavbarComponent, HeaderComponent,CutBrandComponent,SeperatorComponent,MobileNavComponent,AlertsComponent,StaffComponent,SpinnerComponent, ProfileComponent,HeaderTwoComponent],
  imports: [
    CommonModule,
    NgxArcTextModule,
    RouterModule
  ]
})
export class ComponentsModule { }
