import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class ConfigIpToUseService {
	public ip: BehaviorSubject<Boolean> = new BehaviorSubject(false);
	public is_internal:BehaviorSubject<Boolean> = new BehaviorSubject(false);
	internal = 'https://10.165.41.22';
	// internal = 'http://10.20.50.85';
	external = 'http://197.155.226.213';
	constructor(private http: HttpClient) {
		http.get(this.internal + "/api/mobile/covid", { 'observe': 'response' }).toPromise()
			.then(data => {

				this.is_internal.next(true);
				sessionStorage.setItem('ipAddress', this.internal);
			})
			.catch(reason => {
				sessionStorage.setItem('ipAddress', this.external);
			})
			.finally(() => {

				this.ip.next(true);
			});


	}

}
