import { Component, OnInit } from '@angular/core';
import { StaffData } from 'src/app/data/staff-data';
import { Staff } from 'src/app/model/staff';

@Component({
	selector: 'app-about-us',
	templateUrl: './about-us.component.html',
	styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
	selectedProfile: Staff;
	staff:Staff[]
	constructor() {
		this.staff = new StaffData().allStaff
	}

	ngOnInit(): void {
	}
	selectProfile(id){
		try {
			this.selectedProfile = this.staff.filter(st=>st.id==id)[0]

		} catch (error) {
			this.selectedProfile = null
		}finally{
			window.scroll(0,0)
		}
	}

}
