import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { News } from 'src/app/model/news';
import { Notice } from 'src/app/model/notice';
import { NewsService } from 'src/app/service/news.service';
import { ServerDetails } from 'src/app/service/server-details';
import { SpinnerService } from 'src/app/spinner-service.service';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent implements OnInit {

	title: string = '';
	article: Notice;
	news: Notice[];
	// mainNews: News;

	serverDetails: ServerDetails = new ServerDetails();
	constructor(
		private spinner: SpinnerService,
		private route: ActivatedRoute,
		private newsService: NewsService
	) {

		this.route.paramMap.subscribe(info => {
			const id = <number><unknown>info.get('id');
			// console.log('id',id);

			if (id) {
				this.newsService.getNoticeById(id).subscribe(article => {
					// console.log('articale',article);
					this.article = article;
					this.title = "News: " + article.title;
					console.log(this.article)
					window.scroll(0, 0);
				});
			}
			this.newsService.getNotice().subscribe(nhau => this.news = nhau);
			// this.newsService.getMainNews().subscribe(nhau => this.mainNews = nhau);
			// this.shuffle(this.news);
		})
		// const id = <number><unknown>this.route.snapshot.paramMap.get('id');
	}
	readMore(id){
		alert(id)
	}

	ngOnInit(): void {
		this.spinner.stop();
	}
	ngOnDestroy(): void {
		this.spinner.start();
	}

	 bytesToSize(bytes) {
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes == 0) return '0 Byte';
		var i = parseInt((Math.floor(Math.log(bytes) / Math.log(1024)).toString()));
		return Math.round((bytes / Math.pow(1024, i))) + ' ' + sizes[i];
	 }

}
