import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, flash, slideInLeft, slideOutLeft } from 'ngx-animate';
import { SpinnerService } from 'src/app/spinner-service.service';
import { BehaviorSubject } from 'rxjs';
import { DataMonitorService } from '../data-monitor.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('slideInLeft', [transition('* => *', useAnimation(slideInLeft,{
      params: { timing: 1, delay: 1}
    }))]),
    trigger('slideOutLeft', [transition('* => *', useAnimation(slideOutLeft,{
      params: { timing: 1, delay: 1}
    }))]),
  ],
})
export class ContentComponent implements OnInit, OnDestroy {
  slideInLeft: any;
  slideOutLeft:any;
  constructor(public spinner:SpinnerService,private dataMonitor:DataMonitorService) {
    this.spinner.start();
   }

  ngOnInit(): void {
    this.dataMonitor.getStatus().subscribe(data=>{
        if(data==6){
            this.spinner.stop();
      }
    })
    
  }
  ngOnDestroy(){
    this.dataMonitor.close();
    this.spinner.start();
  }

}
