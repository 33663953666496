import { Component, OnInit } from '@angular/core';
import { NewsService } from 'src/app/service/news.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-alerts',
	templateUrl: './alerts.component.html',
	styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
	displayed: boolean = false;
	mainNews;
	constructor(private newsService: NewsService, private router: Router) {
		router.events.subscribe(ev => {
			// if(ev.url!='/main'){
			// 	this.displayed = false;
			// }
			this.displayed = false;
			this.vhara();
		});
		this.newsService.getNotice().subscribe(data => {
			this.mainNews = data;


			if (data == null) {
				this.displayed = false;
				this.vhara();
			}
		})
	}

	ngOnInit(): void {
		// document.querySelector('#close').addEventListener((e,i)=>{
		//   this.displayed = !this.displayed;
		// });

	}

	vhara() {
		try {
			document.querySelector('#sticky-notice').classList.add('disappear');

		} catch (ex) {

		}
		// setTimeout()
	}

	getTimeStamp(news) {
		return ` <span class="badge badge-light badge-pill small ">
		posted: ${new Date(news.updated_at).getDate()} /  ${new Date(news.updated_at).getUTCMonth() + 1} / ${new Date(news.updated_at).getFullYear()}
	</span>
		`
	}
}
