import { Component, OnDestroy, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/spinner-service.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {

    title:string = "Privacy Policy"
     constructor(
       private spinner:SpinnerService
     ) { }
   
     ngOnInit(): void {
       this.spinner.stop();
     }
     ngOnDestroy():void {
       this.spinner.start();
     }

}
