import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { FindProgramComponent } from './find-program/find-program.component';
import { AuxMenuComponent } from './aux-menu/aux-menu.component';
import { NewsComponent } from './news/news.component';
import { EventsComponent } from './events/events.component';
import { StudyingAtCutComponent } from './studying-at-cut/studying-at-cut.component';
import { ContentComponent } from './content/content.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { SliderComponent } from './slider/slider.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-bootstrap';
import { CutShowcaseComponent } from './cut-showcase/cut-showcase.component';
import { GalleryComponent } from './gallery/gallery.component';
import { PaginateComponent } from './paginate/paginate.component';



@NgModule({
  declarations: [FindProgramComponent,AuxMenuComponent, NewsComponent, EventsComponent, StudyingAtCutComponent, ContentComponent, SliderComponent, CutShowcaseComponent, GalleryComponent, PaginateComponent],
  exports: [ContentComponent,NewsComponent,EventsComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
    TypeaheadModule.forRoot(),
    CarouselModule.forRoot(),
    FormsModule
  ],

})
export class HomeModule { }
