import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Testimonial } from '../model/testimonial';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { ServerDetails } from './server-details';

@Injectable({
	providedIn: 'root'
})
export class TestimonialService {
	serverDetails: ServerDetails = new ServerDetails();
	testimonials: Observable<Testimonial[]>;
	homePageTestimonials: Observable<Testimonial[]>;

	constructor(
		private http:HttpClient
	) { }

	getAll(){
		if(!this.testimonials){
			this.testimonials = this.http.get<Testimonial[]>(`${this.serverDetails.serverUrlForApi}/testimonials`).pipe(
				map(data => data),
				publishReplay(1), // this tells Rx to cache the latest emitted
				refCount()
			 );
		}
		return this.testimonials;
	};
	getForHomePage(){
		if(!this.homePageTestimonials){
			this.homePageTestimonials = this.http.get<Testimonial[]>(`${this.serverDetails.serverUrlForApi}/testimonials/home`).pipe(
				map(data => data),
				publishReplay(1), // this tells Rx to cache the latest emitted
				refCount()
			 );
		}
		return this.homePageTestimonials;
	}
}
