import { title } from 'mdbootstrap';
import { Component, OnInit } from '@angular/core';
import { SearchService } from 'src/app/service/search.service';
import { SearchResult } from '../../model/search-result';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebUrlService } from 'src/app/service/web-url.service';
import { ServerDetails } from '../../service/server-details';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

	title = "Search Results"
	searchResults:SearchResult[];
	query = "";
	serverDetails = new ServerDetails();
	constructor(
		private searchService:SearchService,
		private route: ActivatedRoute,
		public urlService: WebUrlService
	) {
		this.searchResults = searchService.searchResults.value;
		this.searchService.searchResults.subscribe(res=>{
			this.searchResults = res
		});
		this.query = this.route.snapshot.params['query'];

		if (!this.searchResults) {
			this.searchService.search(this.query).subscribe(res=>{
			});
		}
	}

	ngOnInit(): void {
	}

	search(query){
		this.query = query;
		if(query.length<3){
			return;
		}
		this.searchService.search(query).subscribe(info=>{
			// this.searchResults = info;

		})
	}

	stripTags(para):string{
		return para.replace(/<.*?>/g, ''); // replace tags
	}
}
