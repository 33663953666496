import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerDetails } from './server-details';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgramSearchLogService {

  serverDetails = new ServerDetails();
  constructor(private http: HttpClient) {}

  public sendLog(searchText,programExist):Observable<any>{
    console.log(searchText);
    return this.http.post(this.serverDetails.serverUrlForApi+"/program/search",{search_text:searchText, program_exist:programExist});
  }
}
