import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-studying-at-cut',
  templateUrl: './studying-at-cut.component.html',
  styleUrls: ['./studying-at-cut.component.scss']
})
export class StudyingAtCutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void
  {
	
  }

}
