import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from './animations';
import { ConfigIpToUseService } from './service/config-ip-to-use.service';
import { SpinnerService } from './spinner-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ]
})
export class AppComponent implements OnInit{
  title = 'cutFrontend';
  resolvingIp = false;
//   constructor(private wow:ConfigIpToUseService,private spinner:SpinnerService){
  constructor(private spinner:SpinnerService){
    // var ipCheck = this.wow.ip.subscribe(data=>{
    //   console.log("Heys")
    //   if(data){
        // this.resolvingIp=true;
        this.spinner.stop();
        // ipCheck.unsubscribe()
        // ipCheck.remove(ipCheck);
    //   }else{
        // this.spinner.start();
    //   }
    // });
  }
  ngOnInit(): void {

  }
}
