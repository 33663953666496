import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-portal-login',
  templateUrl: './student-portal-login.component.html',
  styleUrls: ['./student-portal-login.component.scss']
})
export class StudentPortalLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
