import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from './pages/home/content/content.component';
import { ProgramsComponent } from './pages/programs/programs.component';
import { ProgramComponent } from './pages/program/program.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { SchoolService } from './service/school.service';
import { SchoolComponent } from './pages/school/school.component';
import { NewsComponent } from './pages/news/news.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { ArticleComponent } from './pages/article/article.component';
import { StaffComponent } from './components/staff/staff.component';
import { TimetablesComponent } from './pages/timetables/timetables.component';
import { StudentPortalLoginComponent } from './pages/student-portal-login/student-portal-login.component';
import { FinancialStatementsComponent } from './pages/financial-statements/financial-statements.component';
import { FeesStructureComponent } from './pages/fees-structure/fees-structure.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AdmissionsComponent } from './pages/admissions/admissions.component';
import { MaintenenceComponent } from './pages/maintenence/maintenence.component';
import { NoticeComponent } from './pages/notice/notice.component';
import { SearchComponent } from './pages/search/search.component';
import { NewsletterComponent } from './pages/newsletter/newsletter.component';
import { EvaluationAndMonitoringComponent } from './pages/evaluation-and-monitoring/evaluation-and-monitoring.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { QualityAssuranceComponent } from './pages/quality-assurance/quality-assurance.component';


const routes: Routes = [
  // {path: '/home', component: ContentComponent},
  { path: '', component: ContentComponent },
  { path: 'programs/:id', component: ProgramComponent },
  { path: 'financialStatements', component: FinancialStatementsComponent },
  { path: 'programs', component: ProgramsComponent },
  { path: 'school/:id', component: SchoolComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'newsData', component: NewsletterComponent },
  { path: 'timetables', component: TimetablesComponent },
  { path: 'fees-structure', component: FeesStructureComponent },
  { path: 'students', component:StudentPortalLoginComponent },
  { path: 'contact', component: ContactUsComponent},
  { path: 'evaluation-and-monitoring', component: EvaluationAndMonitoringComponent},
  { path: 'quality-assurance', component: QualityAssuranceComponent},
  { path: 'privacy-policy', component: PrivacyPolicyComponent},
  { path: 'news', component: NewsComponent},
  { path: 'news/:id', component: ArticleComponent},
  { path: 'notices/:id', component: NoticeComponent},
  { path: 'comingsoon', component: ComingSoonComponent},
  { path: 'search-result', component: SearchComponent},
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full'
  },
  { path: 'alumni', loadChildren: () => import('./alumni/alumni.module').then(m => m.AlumniModule) },
  { path: 'directorate/:id', loadChildren: () => import('./directorate/directorate.module').then(m => m.DirectorateModule) },
  { path: 'student-affairs', loadChildren: () => import('./student-affairs/student-affairs.module').then(m => m.StudentAffairsModule) },
  { path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
  { path: 'vacancies', loadChildren: () => import('./vacancies/vacancies.module').then(m => m.VacanciesModule) },
  { path: 'academy', loadChildren: () => import('./academy/academy.module').then(m => m.AcademyModule) },
  { path: 'admissions', component: AdmissionsComponent},
  { path: 'mm', component: MaintenenceComponent},
  { path: 'faqs', loadChildren: () => import('./pages/faqs/faqs.module').then(m => m.FaqsModule) },
  { path: '**', component: ComingSoonComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
