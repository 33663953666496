import { Component, OnInit, OnDestroy } from '@angular/core';
import { SchoolService } from 'src/app/service/school.service';
import { School } from 'src/app/model/school';
import { Router } from '@angular/router';
import { DirectorateService } from 'src/app/service/directorate.service';
import { Directorate } from 'src/app/model/directorate';
import { WebUrlService } from 'src/app/service/web-url.service';
import { ConfigIpToUseService } from 'src/app/service/config-ip-to-use.service';

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.scss']
})
export class HeaderTwoComponent implements OnInit {

	schools: Array<any> = [];
	directorates: Directorate[];
	is_internal:Boolean = false ;

	tag:string ="technology   innovation    wealth"
	constructor(
	  private schoolService: SchoolService,
	  private directorateService: DirectorateService,
	  public webUrlService:WebUrlService,
	  private router: Router,
	  public web_ip:ConfigIpToUseService
	) {
	  web_ip.is_internal.subscribe(data=>{
		  this.is_internal = data
	  });
	  directorateService.getAll().subscribe(data=>{
		this.directorates = data;
	  })
	  // let lodza =
	  schoolService.getAllSchools().subscribe((data:Array<any>)=>{
		this.schools = data.map(info=>{info.url=this.getUrl(info.id); return info;});
	  //   lodza.unsubscribe();
	  });



	}

	ngOnInit(): void {
	  window.onscroll = () => {

		if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {

		  document.querySelectorAll('.cutvalue').forEach(e=>{
			e.classList.add('d-none');
		  });
		  document.querySelector('#social').classList.add('d-none');
		  document.querySelector('#brand').classList.remove('d-none');

		  document.querySelector('#aux-menu').classList.add('d-none');
		  document.querySelector('#logo').classList.add('img-small');
		  document.querySelector('#logo').classList.remove('pb-4');
		  document.querySelector('#primary-menu').classList.add('shadow');
		  document.querySelector('#pl').classList.remove('x-large');
		  document.querySelector('#pr').classList.remove('x-large');

		  document.querySelectorAll('.halt').forEach(a => {
			a.classList.add('fixed-position');
		  });



		} else {

		  document.querySelector('#social').classList.remove('d-none');
		  document.querySelector('#brand').classList.add('d-none');

		  document.querySelectorAll('.cutvalue').forEach(a =>{
			a.classList.remove('d-none');
		  });
		  document.querySelector('#aux-menu').classList.remove('d-none');
		  document.querySelector('#logo').classList.remove('img-small');
		  document.querySelector('#logo').classList.add('pb-4');
		  document.querySelector('#pl').classList.add('x-large');
		  document.querySelector('#pr').classList.add('x-large');

		  document.querySelectorAll('.halt').forEach(a => {
			a.classList.remove('fixed-position');
		  })

		  // document.querySelector("#logo").classList.add("display-40");
		}

	  }

	}

	gotoSchool(id) {
	  this.router.navigateByUrl('school/' + id);
	}

	goHome() {
	  this.router.navigate(['/']);
	}

	getUrl(id){
		return this.webUrlService.getUrl(id);
	}

	directoryUrl(directorate){
		if(directorate.url){
			return directorate.url;
		}
		return 'directorate/'+directorate.id;
	}

	  navigate(url){
		  window.location.href = url
	  }
}
