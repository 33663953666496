import { Component, OnInit } from '@angular/core';
import { Events } from 'src/app/model/events';
import { News } from 'src/app/model/news';
import { Notice } from 'src/app/model/notice';
import { EventsService } from 'src/app/service/events.service';
import { NewsService } from 'src/app/service/news.service';
import { NoticeService } from 'src/app/service/notice.service';
import { ServerDetails } from 'src/app/service/server-details';
import { SpinnerService } from 'src/app/spinner-service.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
	title: string = 'News'
	news: News[];
	news_main: News;
	events: Events[];
	page = 1;
	pageSize = 8;
	serverDetails = new ServerDetails();
	notices: Notice[]
	newsLetter: Notice[];
	constructor(
		private spinner: SpinnerService,
		private newsService: NewsService,
		private eventsService: EventsService,
		private noticeService: NoticeService
	) {
		this.newsService.getAllNews().subscribe(news => {
			this.news = news;
		});
		this.noticeService.getAllnewsletter().subscribe(notices => {
			this.notices = notices
			this.newsLetter = notices.filter(
				(notice) => notice.notice_category_id === 24
			);

		})
		this.newsService.getMainNews().subscribe(main => this.news_main = main);
		this.eventsService.getAllEvents().subscribe(ev => this.events = ev);
	}
	ngOnDestroy(): void {
		this.spinner.start();
	}

	ngOnInit(): void {
		this.spinner.stop();
	}

	picUrl(url) {

		return `${this.serverDetails.serverUrlForStorage}/${url}`;
	}

	shuffle(array) {
		array.sort(() => Math.random() - 0.5);
	}

}
