import { Component, OnInit } from '@angular/core';
import { NewsService } from 'src/app/service/news.service';
import { News } from 'src/app/model/news';
import { CarouselConfig } from 'ngx-bootstrap/carousel';

import { DataMonitorService } from '../data-monitor.service';
import { ServerDetails } from 'src/app/service/server-details';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 30000, noPause: true, showIndicators: true } }
  ]
})
export class SliderComponent implements OnInit {

  sliderNews:Array<any> = [];
  mainNews:News;
  serverDetails = new ServerDetails();
  constructor(
    private newsService:NewsService,
    private dataMonitor:DataMonitorService) {
    this.newsService.getNewsForSlider().subscribe((data:Array<News>)=>{
      this.sliderNews = data;
      this.dataMonitor.changeStatus();
	});
	this.newsService.getMainNews().subscribe(data=>(this.mainNews = data));
  }

  ngOnInit(): void {
  }

  getBackgroundImage(news:News){
    return "url("+this.serverDetails.serverUrlForStorage+'/'+news.picUrl+") no-repeat  center center/cover";
  }
  getUrl(news){
	  let url = `/news/${news.id}`;
	  if(news.linkUrl){
		 url = news.linkUrl.substr().includes('http')?news.linkUrl:this.serverDetails.serverUrlForStorage+'/'+news.linkUrl;
	  }
	  var win = window.open(url, '_blank');

  }

}
