import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from 'src/app/spinner-service.service';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit, OnDestroy {

  constructor(
    private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.stop();
  }
  ngOnDestroy():void {
    this.spinner.start();
  }
}
