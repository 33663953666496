import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from 'src/app/spinner-service.service';
import { TimetableService } from 'src/app/service/timetable.service';
import { BehaviorSubject } from 'rxjs';
import { EventsService } from 'src/app/service/events.service';

@Component({
  selector: 'app-timetables',
  templateUrl: './timetables.component.html',
  styleUrls: ['./timetables.component.scss']
})
export class TimetablesComponent implements OnInit, OnDestroy {
  title:string ="TimeTables & Events"
  private monitorLoading:BehaviorSubject<number> = new BehaviorSubject(0);
  $timetable;
  $events;
  storageUrl;
  constructor(
    private spinner: SpinnerService,
    private timetableService:TimetableService,
    private eventsService:EventsService
  ) {
    this.storageUrl = this.timetableService.serverDetails.serverUrlForStorage;
   }

  ngOnInit(): void {
    this.timetableService.getAllTimetables().subscribe(data=>{
      this.$timetable = data;
      //console.log(this.monitorLoading.value);
      this.monitorLoading.next(this.monitorLoading.value+1);
      //console.log(this.monitorLoading.value);
    });

    this.eventsService.getAllEvents().subscribe(data=>{
      this.$events=data;
      this.monitorLoading.next(this.monitorLoading.value+1);
    })

    this.monitorLoading.subscribe(status=>{
      if(status==2){
        this.spinner.stop();
      }
    })
  }
  ngOnDestroy():void{
    this.monitorLoading.unsubscribe();
    this.spinner.start();
  }

}
