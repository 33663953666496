import { Component, OnDestroy, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/spinner-service.service';

@Component({
  selector: 'app-evaluation-and-monitoring',
  templateUrl: './evaluation-and-monitoring.component.html',
  styleUrls: ['./evaluation-and-monitoring.component.scss']
})
export class EvaluationAndMonitoringComponent implements OnInit, OnDestroy {

    title:string = "Monitoring And Evaluation"
    constructor(
      private spinner:SpinnerService
    ) { }
  
    ngOnInit(): void {
      this.spinner.stop();
    }
    ngOnDestroy():void {
      this.spinner.start();
    }

}
