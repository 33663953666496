import { Component, OnInit, OnDestroy } from '@angular/core';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute } from '@angular/router';
import { NewsService } from 'src/app/service/news.service';
import { News } from 'src/app/model/news';
import { ServerDetails } from 'src/app/service/server-details';
import { SpinnerService } from 'src/app/spinner-service.service';

@Component({
	selector: 'app-article',
	templateUrl: './article.component.html',
	styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit, OnDestroy {
	title: string = '';
	article: any;
	news: News[];
	mainNews: News;
	serverDetails: ServerDetails = new ServerDetails();
	constructor(
		private spinner: SpinnerService,
		private route: ActivatedRoute,
		private newsService: NewsService
	) {

		this.route.paramMap.subscribe(info => {
			const id = <number><unknown>info.get('id');
			// console.log('id',id);

			if (id) {
				this.newsService.getNewsById(id).subscribe(article => {
					// console.log('articale',article);
					this.article = article;
					this.title = "News: " + article.title;
					window.scroll(0, 0);
				});
			}
			this.newsService.getAllNews().subscribe(nhau => this.news = nhau);
			this.newsService.getMainNews().subscribe(nhau => this.mainNews = nhau);
			// this.shuffle(this.news);
		})
		// const id = <number><unknown>this.route.snapshot.paramMap.get('id');
	}
	readMore(id){
		alert(id)
	}

	ngOnInit(): void {
		this.spinner.stop();
	}
	ngOnDestroy(): void {
		this.spinner.start();
	}
	shuffle(array) {
		array.sort(() => Math.random() - 0.5);
	}

}
