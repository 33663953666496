import { Component, OnInit } from '@angular/core';
import { TestimonialService } from 'src/app/service/testimonial.service';
import { Testimonial } from 'src/app/model/testimonial';
import { ServerDetails } from 'src/app/service/server-details';

@Component({
	selector: 'app-testimonials',
	templateUrl: './testimonials.component.html',
	styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
	serverDetails:ServerDetails = new ServerDetails();
	testimonials:Testimonial[];
	constructor(
		private testimonialService:TestimonialService
	) {
		// console.log('testimonial');

		this.testimonialService.getForHomePage().subscribe(
			info=>{this.testimonials=info;console.log(info);

			});
	}

	ngOnInit() {
	}


	// shuffle(array) {
	// 	array.sort(() => Math.random() - 0.5);
	//   }
}
