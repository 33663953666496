import { Component, OnInit, OnDestroy } from '@angular/core';
import { Program } from 'src/app/model/program';
import { Router, ActivatedRoute } from '@angular/router';
import { ProgramService } from 'src/app/service/program.service';
import { SpinnerService } from 'src/app/spinner-service.service';
import { Parent } from 'src/app/model/parent';
import { School } from 'src/app/model/school';
import { WebUrlService } from 'src/app/service/web-url.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ProgrammeAttendanceType } from 'src/app/Models/programme-attendance-type';
import { ProgrammeAttendanceTypeService } from 'src/app/service/programme-attendance-type.service';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})


export class ProgramComponent implements OnInit, OnDestroy  {

  programmeAttendanceType = new ProgrammeAttendanceType();

  program:any;
  school:any;
  programContacts:any;
  programmeAttendanceTyp:any;
  parent: Parent;
  courseSynopsis:any;
  entryTypes:any=[];
  myUrl;
  data: any;


  constructor(
    private route:ActivatedRoute,
    private programService:ProgramService,
    public spinner:SpinnerService,
    public webUrlService:WebUrlService,
    public programmeAtendanceType:ProgrammeAttendanceTypeService
  ) {
    this.myUrl = this.route.snapshot.paramMap.get('id');
    if (this.myUrl) {
      this.programService.getProgramById(webUrlService.getID(this.myUrl)).subscribe((data:any)=>{
        this.program = data.program;
		this.programContacts=data.programContacts;
		this.courseSynopsis=data.courseSynopsis;
        this.programmeAttendanceTyp = data.programAttendanceType
        // console.log(this.programmeAttendanceTyp );
        this.school = data.program.school;
        this.parent = {
          url:'/school/'+webUrlService.getUrl(this.school.id),
          title: this.school.name
        };
        this.entryTypes = data.entryTypes;
        this.entryTypes.forEach((element,index) => {
          this.programService.getRequirement(this.program.id,index).subscribe(data=>{
            element.myRequirements = data;
          });
        });

      });

    }
   }
  ngOnDestroy(): void {
    this.spinner.start();
  }

  ngOnInit(): void {
    this.spinner.stop();
  }
  downloadFile(id: number) {
    this.programService.downloadFile(id).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' }); // Adjust the MIME type accordingly
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
}



}
