import { Injectable } from '@angular/core';
import { ServerDetails } from './server-details';
import { Gallery } from '../model/gallery';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GalleryCategory } from '../model/gallery-category';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
	serverDetails = new ServerDetails();

  constructor(private http:HttpClient) { }

  public galleryCategories(): Observable<GalleryCategory[]> {
    return this.http.get<GalleryCategory[]>(this.serverDetails.serverUrlForApi + '/getCategoryData');
  }

public galleryPictures(galleryCategoryId: number): Observable<Gallery[]> {
    return this.http.get<Gallery[]>(`${this.serverDetails.serverUrlForApi}/gallery-pictures/${galleryCategoryId}`);
  }

}
