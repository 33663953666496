import { Injectable } from '@angular/core';
import { DirectorateData } from '../data/directorate-data';
import { Directorate } from '../model/directorate';
import { SchoolData } from '../data/school-data';
import { ServerDetails } from './server-details';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DirectorateContact } from '../model/directorateContact';

@Injectable({
  providedIn: 'root'
})
export class DirectorateService {
  serverDetails = new ServerDetails();
  // schoolData = new SchoolData();
  // directorateData:DirectorateData = new DirectorateData();
  public currentDirectorate:BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) { }

  public getAll():Observable<Directorate[]>{
    return this.http.get<Directorate[]>(`${this.serverDetails.serverUrlForApi}/directorates`);
  }

  public getById(id):Observable<Directorate>{
    return this.http.get<Directorate>(`${this.serverDetails.serverUrlForApi}/directorates/${id}`);
  }



}
