import { Component, OnInit } from '@angular/core';
import { SchoolService } from 'src/app/service/school.service';
import { School } from 'src/app/model/school';
import { Program } from 'src/app/model/program';
import { EventsService } from 'src/app/service/events.service';
import { Events } from 'src/app/model/events';

import { WebUrlService } from 'src/app/service/web-url.service';

@Component({
  selector: 'app-cut-programs',
  templateUrl: './cut-programs.component.html',
  styleUrls: ['./cut-programs.component.scss']
})
export class CutProgramsComponent implements OnInit {

  indexSelected: number = 0;
  schools:Array<any>;
  selectedPrograms:Array<Program>;
  selectedSchool:any;
  constructor(
    private schoolService:SchoolService,
    private eventServices:EventsService,
    public webUrlService:WebUrlService,
  ) {
     this.schoolService.getAllSchoolsWithDepartmentsAndProgram().subscribe((data:Array<any>)=>{
	//   this.schools = data;
	  this.schools = data.map(info=>{info.url=this.getUrl(info.id); return info;});
      this.selected(Math.floor((Math.random() * data.length)));
      const changePro = ()=> this.selected(this.indexSelected == data.length-1?0:++this.indexSelected)
      setInterval(changePro

      ,15000);
    })
  }

  ngOnInit(): void {

  }

  selected(index): void{
    this.indexSelected = index;
    this.setSixPrograms();
    this.eventServices.getSchoolEventsForFrontPage(this.selectedSchool.id).subscribe((data:Array<Events>)=>{
      this.selectedSchool.events = data;
    });
    //console.log(this.selectedSchool);
  }

  setSixPrograms(){
    this.selectedSchool = this.schools[this.indexSelected];
    this.selectedPrograms = [];
    for (let j = 0; j < this.schools[this.indexSelected].departments.length && this.selectedPrograms.length<6; j++) {
      const element = this.schools[this.indexSelected].departments[j];
      for (let i = 0; i < element.programs.length && this.selectedPrograms.length<6; i++) {
          this.selectedPrograms.push(element.programs[i]);
      }
    }

  }

  getAbout(){
    if(!this.selectedSchool){
      return "...loading";
    }
    if(this.selectedSchool.description){
      let p1:string = this.selectedSchool.description.about.split("</p>")[0];
      p1 = p1.replace("<p>","").substr(0,200);
      return p1;
    }
    return "..."

  }

  getUrl(id){
	return this.webUrlService.getUrl(id);
  }

}
