import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-cut-brand',
  templateUrl: './cut-brand.component.html',
  styleUrls: ['./cut-brand.component.scss']
})
export class CutBrandComponent implements OnInit {
  @Input('cut_value') cut_value: boolean;
  constructor() {
  }

  ngOnInit(): void {

  }

}
