import { Injectable } from '@angular/core';
import { map, publishReplay, refCount } from 'rxjs/operators';

import { NewsData } from '../data/news-data';
import { News } from '../model/news';
import { SchoolData } from '../data/school-data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServerDetails } from './server-details';
import { Notice } from '../model/notice';

@Injectable({
	providedIn: 'root'
})
export class NewsService {

	serverDetails = new ServerDetails();
	allNews: Observable<Array<News>>;
	frontPageNews: Observable<News[]>;
	sliderNews: Observable<News[]>;
	mainNews: Observable<News[]>;
	notice: Observable<News>;
	constructor(private http: HttpClient) { }

	public getAllNews(): Observable<Array<News>> {

		return this.http.get<Array<News>>(this.serverDetails.serverUrlForApi + '/news');
		// return this.allNews;
	}

	public getNewsById(id: number): Observable<News> {


		return this.http.get<News>(`${this.serverDetails.serverUrlForApi}/news/${id}`);

	}

	public getNoticeById(id):Observable<Notice>{
		return this.http.get<Notice>(`${this.serverDetails.serverUrlForApi}/notice/get/${id}`);
	}

	public getFrontPageNews(): Observable<Array<News>> {

		if (!this.frontPageNews) {
			console.log('from server');

			this.frontPageNews = this.http.get<Array<News>>(this.serverDetails.serverUrlForApi + '/news/frontPage').pipe(
				map(data => data),
				publishReplay(1),
				refCount()
			);

		}
		return this.frontPageNews;
	}

	public getNewsForSlider(): Observable<Array<News>> {

		if (!this.sliderNews) {
			this.sliderNews = this.http.get<Array<News>>(this.serverDetails.serverUrlForApi + '/news/frontPage').pipe(
				map(data => data),
				publishReplay(1),
				refCount()
			);
		}

		return this.sliderNews;
	}

	public getMainNews(): Observable<any> {

		if (!this.mainNews) {
			this.mainNews = this.http.get<Array<News>>(this.serverDetails.serverUrlForApi + '/news/getMainNews').pipe(
				map(data => data),
				publishReplay(1),
				refCount()
			);

		}
		return this.mainNews;
	}

	public getNotice(): Observable<any> {

		if (!this.notice) {
			this.notice = this.http.get<News>(this.serverDetails.serverUrlForApi + '/news/getNotice').pipe(
				map(data => data),
				publishReplay(1),
				refCount()
			);

		}
		return this.notice;
	}
}
