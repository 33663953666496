import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appy-now-lg',
  templateUrl: './appy-now-lg.component.html',
  styleUrls: ['./appy-now-lg.component.scss']
})
export class AppyNowLgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
