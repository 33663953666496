import { Injectable } from '@angular/core';
import { ServerDetails } from './server-details';
import { HttpClient } from '@angular/common/http';
import { StaffData } from '../data/staff-data';
import { Staff } from '../model/staff';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  serverDetails = new ServerDetails;
  staffData = new StaffData().allStaff
  constructor(
    private http:HttpClient
  ) { }

  public  getAllStaff():Staff[] {
    // console.log(this.staffData);
    return this.staffData;
  }
  public getAllStaffFromServer():Observable<Staff[]>{
    return this.http.get<Staff[]>(this.serverDetails.serverUrlForApi + '/staff');
  }
  public getStaffByIDFromServer(id):Observable<Staff>{
    return this.http.get<Staff>(`${this.serverDetails.serverUrlForApi}/staff/${id}`);
  }
  public getStaffById(id):Staff{
    try {
      return this.getAllStaff().filter(staff=>staff.id==id)[0]

    } catch (error) {
      return null
    }
  }
}
