import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService } from 'src/app/service/events.service';
import { SpinnerService } from 'src/app/spinner-service.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'app-financial-statements',
  templateUrl: './financial-statements.component.html',
  styleUrls: ['./financial-statements.component.scss']
})
export class FinancialStatementsComponent implements  OnInit, OnDestroy{
  title:String = "Financial Statements";
  private monitorLoading:BehaviorSubject<number> = new BehaviorSubject(0);
  $timetable;
  $events;

  constructor(
    private spinner: SpinnerService,
    private eventsService:EventsService
  ) { }
  ngOnDestroy(): void {
    this.monitorLoading.unsubscribe();
    this.spinner.start();
  }

  ngOnInit(): void {
    this.eventsService.getAllEvents().subscribe(data=>{
      this.$events=data;
      this.monitorLoading.next(this.monitorLoading.value+1);
    })

    this.monitorLoading.subscribe(status=>{
      if(status==2){
        this.spinner.stop();
      }
    })
  }

}
