import { Component, OnInit, Input } from '@angular/core';
import { ServerDetails } from 'src/app/service/server-details';
import { Parent } from 'src/app/model/parent';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input('title') title:string;
  @Input('parent') parent: Parent;
  @Input('picUrl') picUrl:string;

  serverDetails:ServerDetails = new ServerDetails();
  constructor() {


  }

  ngOnInit(): void {

    // console.log('pic Url-init',this.serverDetails.serverUrlForStrorage+'/'+this.picUrl);
  }
  getImage(){
    return this.picUrl? `url('${this.serverDetails.serverUrlForStorage}/${this.picUrl}')` :'url(assets/img/program-bg.jpeg)'
  }

}
