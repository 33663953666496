
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServerDetails } from './server-details';
import { SearchResult } from '../model/search-result';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SearchService {
	serverDetails = new ServerDetails();
	searchResults:BehaviorSubject<SearchResult[]> = new BehaviorSubject(null);
  	constructor(
		  private http:HttpClient
	  ) { }

	  public  search(query:string):Observable<SearchResult[]>{
		let params = new HttpParams().set('query', query);
		return this.http.get<SearchResult[]>(`${this.serverDetails.serverUrlForApi}/program/search/web`,{params: params })
		.pipe(
			map(res=>{
				localStorage.setItem('searchResults',JSON.stringify(res));
				this.searchResults.next(res);
				return res;
			})
		);
	  }


}
