import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Notice } from '../model/notice';
import { ServerDetails } from './server-details';

@Injectable({
	providedIn: 'root'
})
export class NoticeService {
	serverDetail:ServerDetails = new ServerDetails();
	constructor(
		private _http:HttpClient
	) {
	}

	public getAll():Observable<Notice[]>{
		return this._http.get<Notice[]>(`${this.serverDetail.serverUrlForApi}/notice/web`);
	}
	public getAllnewsletter():Observable<Notice[]>{
		return this._http.get<Notice[]>(`${this.serverDetail.serverUrlForApi}/notice/newsletter`);
	}

	public getNoticeById(id):Observable<Notice>{
		return this._http.get<Notice>(`${this.serverDetail.serverUrlForApi}/notice/get/${id}`);
	}

}
