import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginate',
  templateUrl: './paginate.component.html',
  styleUrls: ['./paginate.component.scss']
})
export class PaginateComponent implements OnInit {

	@Input() totalPages: number;
	@Input() currentPage: number;
	@Output() pageChange: EventEmitter<number> = new EventEmitter<number>(); 

	get pages(): number[] {
	  return Array(this.totalPages).fill(0).map((_, index) => index + 1);
	}

	goToPage(page: number): void {
	  if (page !== this.currentPage) {
		this.pageChange.emit(page);
	  }
	}

  constructor() { }

  ngOnInit(): void {
  }

}
