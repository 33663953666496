import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintenence',
  templateUrl: './maintenence.component.html',
  styleUrls: ['./maintenence.component.scss']
})
export class MaintenenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
