import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { HomeModule } from './pages/home/home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgramsComponent } from './pages/programs/programs.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormsModule } from '@angular/forms';
import { ProgramComponent } from './pages/program/program.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { SchoolComponent } from './pages/school/school.component';
import { HttpClientModule } from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// import third-party module
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { NewsComponent } from './pages/news/news.component';
import { ArticleComponent } from './pages/article/article.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { TimetablesComponent } from './pages/timetables/timetables.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { StudentPortalLoginComponent } from './pages/student-portal-login/student-portal-login.component';
import { FinancialStatementsComponent } from './pages/financial-statements/financial-statements.component';
import { FeesStructureComponent } from './pages/fees-structure/fees-structure.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AdmissionsComponent } from './pages/admissions/admissions.component';
import { HeaderComponent } from './pages/admissions/header/header.component';
import { MaintenenceComponent } from './pages/maintenence/maintenence.component';
import { FindProgramComponent } from './pages/home/find-program/find-program.component';
import { CutProgramsComponent } from './pages/admissions/cut-programs/cut-programs.component';
import { TestimonialsComponent } from './pages/admissions/testimonials/testimonials.component';
import { NoticeComponent } from './pages/notice/notice.component';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './pages/search/search.component';
import { NewsletterComponent } from './pages/newsletter/newsletter.component';
import { EvaluationAndMonitoringComponent } from './pages/evaluation-and-monitoring/evaluation-and-monitoring.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { QualityAssuranceComponent } from './pages/quality-assurance/quality-assurance.component';



AOS.init();

@NgModule({
  declarations: [
    AppComponent,
    ProgramsComponent,
    ProgramComponent,
    AboutComponent,
    ContactUsComponent,
    SchoolComponent,
  NewsComponent,
	CutProgramsComponent,
    ArticleComponent,
    ComingSoonComponent,
    TimetablesComponent,
    StudentPortalLoginComponent,
    FinancialStatementsComponent,
    FeesStructureComponent,
    AboutUsComponent,
    AdmissionsComponent,
    HeaderComponent,
	MaintenenceComponent,
	TestimonialsComponent,
	NoticeComponent,
	SearchComponent,
	NewsletterComponent,
	EvaluationAndMonitoringComponent,
	PrivacyPolicyComponent,
	QualityAssuranceComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ComponentsModule,
    HomeModule,
    BrowserAnimationsModule,
	HttpClientModule,
	NgbModule,
    NgxPaginationModule,
    AnimateOnScrollModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

 }
