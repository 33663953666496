import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public status: Subject<boolean> = new Subject();
  private _active: boolean = false;
  constructor() { }

  public get active(): boolean {
    return this._active;
  }

  public set active(v: boolean) {
    this._active = v;
    this.status.next(v);
  }

  public start(): void {

    this.active = true;


  }

  public stop(): void {

    setTimeout(() => {
      this.active = false;
    }, 300);
    window.scroll(0,0)




  }
}
