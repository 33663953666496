import { Injectable } from '@angular/core';
import { ServerDetails } from './server-details';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimetableService {

  serverDetails = new ServerDetails();
  constructor(private http: HttpClient) { }

  public getAllTimetables():Observable<any>{
    return this.http.get(this.serverDetails.serverUrlForApi+'/timetable');
  }
}
