import { Component, OnInit } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { ProgramService } from 'src/app/service/program.service';
import { Router } from '@angular/router';
import { Program } from 'src/app/model/program';


import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, flash } from 'ngx-animate';
import { ProgramSearchLogService } from 'src/app/service/program-search-log.service';
import { WebUrlService } from 'src/app/service/web-url.service';

@Component({
  selector: 'app-find-program',
  templateUrl: './find-program.component.html',
  styleUrls: ['./find-program.component.scss'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce))]),
    trigger('flash', [transition('* => *', useAnimation(flash,{
      params: { timing: 3, delay: 2}
    }))]),
  ],
})
export class FindProgramComponent implements OnInit {
  bounce: any;
  flash:any
  selectedValue: string;
  selectedOption: Program;
  programs = [

  ]
  noResult = false;
  constructor(
    private programsService: ProgramService,
    private router:Router,
    private programSearchLogService:ProgramSearchLogService,
    public webUrlService:WebUrlService,
  ) {
    this.programsService.getAllPrograms().subscribe((data:Array<Program>)=>{
      this.programs =data;
    });
  }

  ngOnInit(): void {
  }

  onSelect(event: TypeaheadMatch): void {
    this.selectedOption = event.item;
    this.noResult=false;
    this.programSearchLogService.sendLog(this.selectedOption.title,1).subscribe(data=>{
      // console.log(data);
      this.router.navigateByUrl("programs/"+this.webUrlService.getUrl(this.selectedOption.id));
    });
  }

  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  onEnter(){
    this.noResult=true;
    this.programSearchLogService.sendLog(this.selectedValue,0).subscribe(data=>{});
    console.log('data rauya ey'+this.selectedValue);
  }

}
