import { Component, OnInit, Input } from '@angular/core';
import { Staff } from 'src/app/model/staff';
import { StaffService } from 'src/app/service/staff.service';
import { ServerDetails } from 'src/app/service/server-details';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {
  @Input('staff_id') staff_id:number;
  @Input('serverStaff') serverStaff:boolean;
  modalID:string;
  staff:Staff;
  title:string = "Staff Profile";
  serverDetails:ServerDetails = new ServerDetails();
  constructor(
    private staffService:StaffService
  ) {


  }

  ngOnInit(): void {
    if (this.staff_id) {
      this.modalID = "staff"+this.staff_id;
      if (this.serverStaff) {

        this.staffService.getStaffByIDFromServer(this.staff_id).subscribe(data=>{
          this.staff=data
          this.staff.picUrl = this.serverDetails.serverUrlForStorage+'/'+this.staff.picUrl
        });
      }else{
        this.staff = this.staffService.getStaffById(this.staff_id) ;

      }

    }
  }

}
