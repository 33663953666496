import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apply-button',
  templateUrl: './apply-button.component.html',
  styleUrls: ['./apply-button.component.scss']
})
export class ApplyButtonComponent implements OnInit {

  constructor(private router:Router ) { }

  ngOnInit(): void {
  }

  apply(){
    this.router.navigateByUrl('www.cut.ac.zw/portal/index.php/online_application/getstarted');
  }
}
