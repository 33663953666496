import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProgramService } from 'src/app/service/program.service';
import { Program } from 'src/app/model/program';
import { SchoolService } from 'src/app/service/school.service';
import { School } from 'src/app/model/school';
import { SpinnerService } from 'src/app/spinner-service.service';
import { WebUrlService } from 'src/app/service/web-url.service';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit, OnDestroy {
  title:string = 'Cut Programs';
  p: number = 1;
  programs: Program[];
  schools:School[];
  filteredPrograms: Program[];
  constructor(
    private spinner: SpinnerService,
    private programsService: ProgramService,
    private schoolService: SchoolService,
    public webUrlService:WebUrlService,
  ) {
   this.programsService.getAllPrograms().subscribe((data:Array<Program>)=>{
    this.filteredPrograms = this.programs = data;
	console.log(this.filteredPrograms);


   });

   this.schoolService.getAllSchools().subscribe(data=>this.schools=data);
   }
  ngOnDestroy(): void {
this.spinner.start();
  }

  ngOnInit(): void {
    this.spinner.stop()
  }

  limit(text,limit){
    return (text.substr(0,limit) + '...').replace('<p>','');
  }

  filterPrograms(search){
    this.filteredPrograms = (search) ?
    this.programs.filter( p => p.title.toLowerCase().includes(search.toLowerCase())) : this.programs;

  }
  navigateProg(p){
    this.p = this.p+p;
    window.scroll(0,0);
  }

}
