import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from 'src/app/spinner-service.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {
  title:string = "Contact Us"
  constructor(
    private spinner:SpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.stop();
  }
  ngOnDestroy():void {
    this.spinner.start();
  }

}
