import { Staff } from "../model/staff";

export class StaffData {
	allStaff: Array<Staff> = [
		{
			id: 1,
			first_name: "David",
			last_name: "Simbi",
			initials: "D.J",
			title: "Professor",
			position: "Vice Chancellor",
			picUrl: "assets/img/vc.jpg",
			departmentId: 0,
			about: `
            <p>Professor David Jambgwa Simbi was born on 6 June 1952 near Nyazura. He enrolled at Dowa Council Primary School in 1959, transferred to Nyazura Mission in 1966 and finally Solusi Secondary School in 1970. David’s first employment was as a Technical Laboratory Assistant and Temporary Secondary School Teacher at Solusi after his ‘O’ Levels in 1972. In 1974, David secured a place and enrolled at Glenside Hospital in Bristol, UK for a Mental Health Nurse Certificate at Brunnel College.</p>
            <p>Concurrently, he studied for his Ordinary national certificate in Maths, Physics and Chemistry. David immediately enrolled for a BSc in Applied Chemistry at the University of Portsmouth in 1977. Graduating with BSc Hons degree in 1981, David was offered a PhD scholarship in Metallurgy specializing in Corrosion Engineering at the University of Leeds and successfully defended his thesis in 1984.</p>
            <p>After completing his PhD David returned to Zimbabwe, and in 1985 was appointed a Manager responsible for Technical Development at ZISCO Steel (Pvt) Ltd. In 1988, David joined the University of Zimbabwe as a lecturer in and was promoted to Senior Lecturer in 1994. He was approached and requested to take up the post of Works Executive at ZISCO in 1999 in a bid to resuscitate and operationalise Blast Furnace No. 4 and returned to the UZ upon completing his task in 2000.&nbsp;</p>
            <p>In 2002, he was promoted to Associate Professor and eventually Full Professor in 2005. Professor Simbi contributed immensely to the Metallurgical Engineering field at the University of Zimbabwe and other Universities within the region. He chaired the Department of Metallurgy for 2 terms between 1991-94 and 1996-99 and also serving as Deputy Dean from 1997-1999 and briefly in 2003 before taking over the full reigns as Dean of the Faculty of Engineering towards the end of the same year. In 2005 he was appointed the Pro Vice Chancellor of Chinhoyi University Technology (C.U.T) and has been the Vice Chancellor of C.U.T from 2006.</p>
            <p>Professor Simbi has held several Visiting appointments (Visiting Research Fellow, Visiting Senior Lecturer and Visiting Professor such prestigious institutions as University of Cape Town, University of Zambia, British Steel Corporation, Duisburg University and Aachen University of Technology both in Germany. He also sits on a number of Boards including Tobacco Industries Marketing Board and SIRDC and he is also the current Chairman of Kwekwe Polytechnic Advisory Council. He was also the Vice Chairman of the Manicaland University of Applied Sciences Foundation Committee. Professor Simbi has taught and continues to teach several courses in Metallurgy and Chemical Engineering ranging from pyrometallurgy, corrosion engineering, construction materials, fuels, energy and environmental studies. He has also successfully supervised 13 MPhil students and 6 DPhil students. Professor Simbi has published over 50 papers in refereed journals. He has also reviewed a number of books mainly in corrosion and has also published numerous technical reports of consultancies. He is currently working on a text book on Corrosion for undergraduate.</p>
            <p>Professor Simbi acknowledges the contributions made by various professionals and people in his academic development. Notable ones are: his cousin, the late Professor Made, the former UZ librarian who took care of all his educational needs during secondary school, his several mentors from the University of Portsmouth and the University of Leeds, his long time colleague and friend, Dr. Chinyamakobvu for providing the necessary competition when it came to research publications and of course his father and mother, who unknowingly provided his foundation as a seasoned metallurgist and chemical engineer.&nbsp;</p>
            <p>Lastly, Professor Simbi does not live on his own. He is a very happily married man to Margaret Masenda-Simbi and the couple has been blessed with three children 2 girls and 1 boy. He draws most of his inspiration from his family, Mrs. Simbi and the children and God Almighty.</p>
            <p>&nbsp;</p>
        `,
		},
		{
			id: 3,
			first_name: "Irvin",
			last_name: "Mpofu",
			initials: "IDT",
			title: "Professor",
			position: "Pro Vice Chancellor",
			picUrl: "assets/img/pro-vc-aca.jpg",
			departmentId: 0,
			about: `
              Prof Irvin DT Mpofu has a BSc Agriculture Honours degree in Animal Science, MSc in Animal Science (Animal Nutrition) and an MBA from the University of Zimbabwe. He has a PhD in Animal Science (Mineral Nutrition) from the University of Pretoria. He did his primary school education at Siboza Primary School and secondary school education at Dadaya High School in Zvishavane.
              <p></p><p>He has over 25 years of experience in higher education in three (3) countries; Namibia, Rwanda and Zimbabwe. Prior to being appointed PVC - Academic Affairs and Administration he was the Director of Research and Graduate Studies and Professorial Chair of Animal Production and Technology at Chinhoyi University of Technology (CUT). </p><p>
              His career in higher education started at the University of Zimbabwe (UZ) where from 1993 to 2006 he served in various capacities as lecturer, senior lecturer and Chairperson of the Department of Animal Science.
              </p><p>From 2006 to 2009 he was the Founding Dean of the first School of Veterinary Medicine in Rwanda.
              </p><p>From 2009 to 2015 he was a Professor of Animal Nutrition at the University of Namibia (UNAM) where he served also as Head of Animal Science Department. He also championed the creation of the first Faculty of Veterinary Medicine in Namibia through the Department of Animal Science. </p><p>
              </p><p>In 2016 he joined Chinhoyi University of Technology as Professorial Chair of the Department of Animal Production and Technology and in 2017 was appointed the Director of Research and Graduate Studies. During this stint he championed commercialisation of research through the Southern African Network for Biosciences (SANBIO) under the theme "Science Meets Business".
              In 2018 he was appointed into the Zimbabwe Council of Higher Education (ZIMCHE) where he chairs the committee on Shapes and Sizes.
              </p><p>In 2019, he was appointed the Pro Vice Chancellor responsible for Academic Affairs and Administration where he has championed the mainstreaming of Doctrine Education 5.0 (that encompasses innovation and industrialisation in addition to the three traditional functions of a university namely teaching, research and outreach). He is now leading the e-learning mode at Chinhoyi University of Technology.
              </p><p>He has done a lot of need driven research finding practical solutions to Animal Nutrition, Feed Formulation, Feed Manufacturing and Applied Animal Feed Science and Technology. His major highlights in developing learning materials and contributing to the body of knowledge include the following publications:
              </p><ul>
              <li>	Three (3) Agriculture Education Study Guides for the International Opel Learning Centre in Namibia;</li>
              <li>	Two (2) Animal Nutrition Study Manuals for the Zimbabwe Open University</li>
              <li>	Two (2) books on livestock i.e. (i) Applied Animal Feed Science and Technology and (ii) Pig Production Science and Technology. </li>
              <li>	Over 40 Referred journal articles, book chapters and technical reports and </li>
              <li>	A software on Feed Formulation.</li>
              </ul>
              <p></p><p>Prof Mpofu has served as external examiner for many universities and reviewer and editor for many prominent referred journals.
              </p><p>He has done significant consultancies on livestock and feed manufacturing development and Climate Smart Agriculture that include:  Climate Smart Livestock and Rangeland Management scoping study and book chapter authoring in the Climate Smart Agriculture Manual for Zimbabwe funded by UNEP DTU Partnership; determination, implementation of capacity gaps in Namibia's National Agricultural and Food Security Investment Plans (NAFSIPS) for Centre for the Coordination of Agricultural Research and Development for Southern Africa (CCARDESA); Climate smart agriculture scoping study for Namibia contracted by the Food Agriculture and Natural Resources Policy Analysis Network (FANRPAN); Feed Manufacturing Plant for the Grain Marketing Board, Investment potentials in the Eastern Province of Rwanda and Livestock Advisory Council of Zimbabwe.
              </p><p>Prof Irvin DT Mpofu Brings a wealth of practical experience in university education, innovation, research commercialization, innovation and industrialization.
                  </p>`,
		},
		{
			id: 2,
			first_name: "Wilbert ",
			last_name: "Mtangi",
			initials: "W",
			title: "Professor",
			position: "Acting Pro-Vice Chancellor ",
			picUrl: "assets/img/mtangi.jpeg",
			departmentId: 0,
			about: `

			<p> Professor Mtangi is a holder of a PhD in Physics with specialization in Condensed Matter Physics from the University of Pretoria, South Africa. His doctoral research, riding on a number of scientific techniques,  was based on the fabrication of electronic and optoelectronic devices on conventional semiconductors. The study also focused on the characterization of defects using spectroscopic methods. From March 2014 to December 2016, he  worked as a postdoctoral fellow at the Weizmann Institute of Science, Israel, where he was a member of the Molecular Electronics research group. Part of the work’s scope involved the development and characterization of chiral materials for photoelectrochemical water splitting. <br>

			<br>
			Professor Mtangi served as the founding coordinator and Executive Director of the Institute of Materials Science, Processing, and Engineering Technology, a post he held from October 2017 to June 2021, subsequently being seconded to the role of Executive Director: Research, Innovation, and Business Incubation. These roles have provided experience in leading multidisciplinary teams and developing strategies for running and managing a School/Directorate. Over the same period, he has been concurrently serving as the leader of the renewable energy research group at Chinhoyi University of Technology. From December 2023 to date, he has been serving as the Acting PVC: Innovation, Enterprise Development, and Industrialization.
			<br><br>
			Over his period of service as the Executive Director: Research, Innovation, and Business Incubation, Prof Mtangi’s knowledge of Intellectual Property (IP) and technology transfer related matters has been enhanced through participation in several workshops and training programmes on technology transfer, organized by the World Intellectual Property Organization (WIPO) and African Regional Intellectual Property Organization (ARIPO). He  is responsible for the establishment of technology transfer processes and systems at Chinhoyi University of Technology’s Innovation Hub, and has been involved in developing strategies aimed at ensuring the commercialization of research and innovation conducted at the university. Additionally, Professor Mtangi is responsible for developing robust Intellectual Property (IP) management systems and ensuring adequate IP awareness campaigns are conducted within the university community to increase IP filing and protection.
			<br><br>
			 In this regard, he  spearheaded the development of standard operating procedures for IP assessment, registration, and licensing for the benefit of the university. Additionally, he has been leading the development of frameworks for the marketing and commercialization of IP. With the innovation hub offering business incubation services, he has led the development of business development and incubation frameworks. In the capacity of Executive Director, he has facilitated the development, negotiation, and assessment of business contracts, as well as the establishment of synergies with industry and entrepreneurial ecosystems within the university and beyond
			</p>`,


		},
		{
			id: 4,
			first_name: "Thomas",
			last_name: "Bhebhe",
			initials: "T",
			title: "Professor.",
			position: "Registrar",
			picUrl: "assets/img/reg.jpg",
			departmentId: 0,
			about: `
            <p>The Registrar, Professor Thomas Bhebhe holds a D.Phil (Malaysia), MSc in Leadership & Change Management (Leeds, UK); M.Ed Admin (UZ); HRM (National University of Singapore), B.Ed Admin(UZ), Post-grad Certificate in Higher Education Management (Wits University, SA); Cert. in Human Resources Management (Israel), CE (UZ); FIPMZ&nbsp;<br>
            <br>
            Prof. Bhebhe was born on the 7th of May 1963 in Chitomborwizi near Chinhoyi as the eldest child in a family of seven. Before joining University Service, He taught for a year before being promoted to the post of Headmaster and he rose through the ranks to become the Ministry of Education Provincial Staffing Officer in charge of Mashonaland West Province where he was overseeing Human Resources issues for over 14,000 teachers, Headmasters and Education Officers in the province. .&nbsp;<br>
            <br>

			In the year 2000, Prof. Bhebhe got a scholarship to study Human Resources Management at the National University of Singapore. He then left civil service to join the University of Zimbabwe as the Director in charge of Human Resources (also called Deputy Registrar, Human Resources). At the University of Zimbabwe, Prof. Bhebhe excelled in serving a huge staff compliment of academic and non-teaching staff at Zimbabwe's biggest and oldest university.</p>
            <p>In 2009, he left the University of Zimbabwe to join Chinhoyi University of Technology as the University Registrar where he continues to carry out his duties as the Chief Administrator reporting to the Vice Chancellor. Prof. Bhebhe has extensive experience as an educationist, educational administrator, corporate leader, HR Executive and University Executive which spans for over 30 years and has won many awards in his educational and administrative career.</p>

			Prof. Bhebhe is a refined scholar and researcher in his own right having won the Academic Excellence Award for his D.Phil. He has been a lecturer for both undergraduates and postgraduates for many years, but he now concentrates on post graduate students due to time constraints. He has supervised many Masters Dissertations. He is also a Supervisor and External Examiner for PhD candidates for some Universities in South Africa, Zambia, Namibia and at Chinhoyi University of Technology. Prof Bhebhe is a grounded scholar who has international recognition. He was Africa’s representative to the Association of International Educators (NAFSA), the largest organisation for university educationists in the world for three years. In this regard, he travelled across the globe representing academics in Africa on the internationalisation agenda. As at December 2021, he had published 36 papers in international double-blind peer reviewed journals. Prof. Bhebhe has amassed vast international exposure in Higher Education having travelled to various parts of the world to study University academic and human resources management practices at such prestigious institutions as Glasgow University, Strathclyde University, Glasgow Caledonian University in Scotland, Newcastle University and Northumbria University in England; Kwameh Nkrumah University of Science and Technology in Ghana, the National University of Singapore, Queensland University of Technology in Australia, Michigan State University, University of Colarado and New York University in the USA, Witwatersrand, KwaZulu Natal, Tshwane and the University of Venda in South Africa among others. This gives him the much needed cutting-edge experience in handling complex administrative matters of a modern university such as CUT. He is a firm believer in team work, hard work and exemplary leadership. Prof. Bhebhe is a keen farmer.
			</p>
			<p>
			He is married to Ruth Bhebhe an educationist and educational administrator by profession, and the couple is blessed with three children (boys) who are now adults; Simplicio a Senior Partner at a Law Firm; Complexio a Medical Doctor and Blessio a Chartered Accountant.
			</p>
			`,
		},
		{
			id: 8,
			first_name: "Dzingayi",
			last_name: "Shangwa",
			initials: "T",
			title: "Mr.",
			position: "Acting Librarian",
			picUrl: "assets/img/shangwa.png",
			departmentId: 0,
			about: `
				<p>
			Mr Dzingayi Shangwa holds an MBA in Information Systems and a BA in Media Studies. He is currently working towards completing an MSc in Library and Information Science under the National University of Science and Technology (NUST). In terms of professional certification, Mr. Shangwa also holds a National Certificate in Library & Information Science. With a career spanning over three decades, he has gained extensive working experience in the domain of academic libraries.

<br><br>He embarked on his journey to fully-fledged librarianship in 1991 as a Library Assistant at Prince Edward School in Harare. Later in the same year, he moved to Masvingo Technical College as an Assistant Librarian. He got promoted to College Librarian in 1996.  Mr Shangwa joined MSU as a library Assistant in 2000 and in 2003 he moved to Chinhoyi University of Technology as a Senior Library Assistant. In 2007, he was appointed Assistant Librarian responsible for library systems and he was further promoted to Sub-librarian in 2018. To date, Mr Shangwa has been serving in his current capacity as the Acting University Librarian since May 2023.

				</p>
			`,
		},
		{
			id: 6,
			first_name: "Josiline",
			last_name: "Chigwada.",
			initials: "J",
			title: "Dr.",
			position: "Librarian",
			picUrl: "assets/img/chigwada.jpg",
			departmentId: 0,
			about: `
				<p>
Dr. Chigwada is not new at CUT as she was the Deputy Librarian before taking Leave of Absence to pursue Post-Doctoral Fellowship with the University of South Africa. During her stint as Deputy Librarian, she did a lot including building a formidable Library team and winning grants to solarise the Library. She has now returned to meet promotion and assume a bigger role as Librarian. Dr. Chigwada is a scholar-par excellence. She holds the following qualifications which make her one of the most highly qualified Librarians on the continent:

<ul>
  <li>PhD in Library & Information Science [University of South Africa]</li>
  <li>MSc in Library & Information Science [NUST]</li>
  <li>Postgraduate Diploma in Management of Electronic Information for Digital Libraries [University of Antwerp, Belgium]</li>
  <li>Postgraduate Diploma in Tertiary Education [BUSE]</li>
  <li>BSc (Hons) in Library & Information Science [NUST]</li>
  <li>Post Graduate Certificate in Modern Library Practices [National Institute of Technical Training and Research (NITTR), Chennai – India]</li>
</ul>

She started her Librarianship career at BUSE where she was an Assistant Librarian before UZ snatched her to be its Sub-Librarian. She then returned to BUSE to assume bigger responsibilities and this time CUT again snatched her and promoted her to the post of Deputy Librarian. After serving at CUT, hunger and thirst for more knowledge and experience led her to apply and win Post-Doctoral Fellowship at the University of South Africa, with specific tasks to research and design digital information systems for open access learning and advanced modern library systems for Africa’s biggest University (The University of South Africa) which has nearly 400,000 students from 130 countries. She embarked on the task and successfully delivered, and she is now back with a bang. She left the biggest university to re-join CUT. Here we don’t talk of size, we talk of quality. That is why we are a CUT above the best. Dr. Chigwada is an accomplished scholar, researcher and academician in her own right. To date, she authored 3 books and penned 21 publications in international high-impact factor peer reviewed journals. She is a part-time lecturer for MSc in Library and Information Science. She brings back to CUT wide experience which will be very useful for CUT students and staff who desire to pursue their dreams through wide reading. Dr. Chigwada is happily married to Isaya Chigwada and the couple is blessed with two children, a boy and a girl, Nathaniel Takudzwa and Joana Thandaza aged 10 and 7 respectively.

				</p>
			`,
		},
		{
			id: 7,
			first_name: "Shakemore",
			last_name: "Chinofunga",
			initials: "S",
			title: "Dr.",
			position: "Executive Director for ICT",
			picUrl: "assets/img/chinoz.jpg",
			departmentId: 0,
			about: `
            <p>Dr. Chinofunga is a highly accomplished professional with a PhD in Information and Communication Technology from Binary University in Malaysia, specializing in Sustainable Digital Transformation and Data Management. He also holds an MSc in Information Systems from the National University of Science and Technology (NUST) in Zimbabwe, and a BSc (Honours) in Information Systems from Midlands State University in Zimbabwe. In addition to his academic qualifications, Dr. Chinofunga has earned various certifications, including Oracle Business Intelligence Enterprise Edition (OBIEE) 10g and 11g, Internal Controls Management, CCNA, Linux Administration, and Big Data Analytics. Born on February 10, 1983, in Harare, Dr. Chinofunga is the eldest son in a family of five children. He is also a motivational speaker in the field of ICT and actively participates in national digital transformation projects. His work includes presentations for the Association of African Universities (AAU) on topics such as Digital University Systems and Automated Registry Workspaces. Dr. Chinofunga is a recognized expert, often consulted by regional universities for his insights on systems automation and ICT governance. A strong proponent of digital solutions, he is also a member of the Computer Society of Zimbabwe. Before joining Chinhoyi University of Technology, Dr. Chinofunga served as a Senior Software Developer at Realfin Global and as a Systems Analyst at Technical Systems. He began his journey at Chinhoyi University in 2011 as an Analyst Programmer, quickly advancing to Database Manager in 2012, and later to Technical Services Manager in 2016, where he led ICT Hardware and Network Infrastructure. His upward trajectory continued, with promotions to ICT Deputy Director in 2019 and Executive ICT Director in 2020.</p>`,
		},
		{
			id: 6,
			first_name: "Bright",
			last_name: "Nyagwaya",
			initials: "B",
			title: "Mr.",
			position: "Librarian",
			picUrl: "assets/img/lib.jpg",
			departmentId: 0,
			about: `
<p>Mr Bright Nyagwaya is the University Librarian. He was born in Nyanga on the 8th of October 1967. He holds a Master's and Bachelor's Degrees in Library and information Science from National University of Science and Technology (Bulawayo). He also attained various diplomas in the library profession at Harare Polytechnic.</p>
<p>Mr Nyagwaya has vast experience in the library profession. Before joining Chinhoyi University of Technology, he worked in various institutions in Zimbabwe as a librarian/Documentalist, which include Ecumenical Documentation Information Centre for Eastern and Southern Africa (EDICESA), Southern African Research and Documentation Centre (SARDC), National Free Library of Zimbabwe and Chinhoyi Technical Teachers College At Chinhoyi University of Technology, prior to becoming the Acting University Librarian, Mr Nyagwaya served various library positions as Senior Library Assistant, Senior Assistant Librarian and Deputy Librarian.</p>    `,
		},
		{
			id: 5,
			first_name: "John",
			last_name: "Hotera",
			initials: "J",
			title: "Mr.",
			position: "Bursar",
			picUrl: "assets/img/bursar.png",
			departmentId: 0,
			about: `
			<p> Mr John Hotera, the University Bursar, is a holder of an MSc in Strategic Management and a Bachelor's Degree in Commerce & Accountancy. He is an Associate member of the Southern African Association of Accountancy (SAAA).
<br>He joined Chinhoyi University of Technology as the Chief Accounting Assistant in 2003.
Subsequently, he rose through the ranks to the position of Deputy Bursar, Finance & Accounting, a post he attained in 2012. Mr Hotera joined the ranks of Chinhoyi University of Technology's Senior Executive as he officially assumed the position of the University Bursar on the 1st of July 2023.
<br><br>Prior to joining Chinhoyi University of Technology, he worked for Zimbabwe Open University for four (4) years in the capacity of an Accounting Assistant, after which he had a decade-long tenure of service under the Ministry of Primary and Secondary Education. He brings immense wealth of financial stewardship experience obtained through years of engagement in both private and public sector entities</p>
            `,
		},
	];
}
