import { Component, OnInit } from '@angular/core';
import { Events } from 'src/app/model/events';
import { EventsService } from 'src/app/service/events.service';
import { DataMonitorService } from '../data-monitor.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  eventsData:Array<Events>;
  constructor(
    private eventsService:EventsService,
    private dataMonitor:DataMonitorService) {
    this.eventsService.getFrontPageEvents().subscribe((data:Array<Events>)=>{
      this.eventsData = data;
      this.dataMonitor.changeStatus();
    });
   }

  ngOnInit(): void {
  }

}
